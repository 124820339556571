import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

import '@/assets/styles/base.stylus'
import '@/assets/styles/classes.stylus'
import '@/assets/styles/form.stylus'
import '@/assets/styles/layout.stylus'

var firebaseConfig = {
  apiKey: 'AIzaSyCLcK1zd9N9K1J7k4D9A-FcS4B1G3MFpQs',
  authDomain: 'coreculture-6a6dc.firebaseapp.com',
  projectId: 'coreculture-6a6dc',
  storageBucket: 'coreculture-6a6dc.appspot.com',
  messagingSenderId: '1002634797792',
  appId: '1:1002634797792:web:31b8f2170d5d6f124b6685'
}
const app = createApp(App)
let appMounted = false
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

firebase.auth().onAuthStateChanged(async user => {
  store.dispatch('updateUser', user)
  // const templates = await firebase.firestore().collection('surveyTemplate').get()
  console.log('auth state changed')
  if (!appMounted) {
    app.use(store)
    app.use(router)
    app.mount('#app')
    appMounted = true
  }
})
