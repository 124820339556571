<template>
  <div class="message-box">
    <i class="flag-icon fas fa-flag-alt"></i>
    <div class="message">{{ message }}</div>
    <base-ui-button class="button" @clicked="$emit('openLatestSurvey')">Tilbage til nyeste version</base-ui-button>
  </div>
</template>

<script>
import BaseUiButton from './BaseUiButton.vue'
export default {
  components: {
    BaseUiButton
  },
  props: {
    message: {
      type: String,
      default: 'Lorem Ipsum Dolor Sectumsembre'
    }
  },
  emits: ['openLatestSurvey']
}
</script>

<style lang="stylus" scoped>
.flag-icon
  width 22px
  height 22px
  color white
  margin-right 1rem

.message-box
  z-index 10
  position fixed
  left 0
  right 0
  bottom 0
  background #f5c95e
  display flex
  align-items center
  padding 1rem

.message
  flex-grow 1

.button
  background-color: #2a6889 !important

</style>
