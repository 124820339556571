<template>
  <div id="page" :class="{ 'theme-dark': darkTheme, 'front-page': isFrontPage }" :style="`background-color: ${bgColor};`">
    <nav id="nav" v-if="!loading && !isAnonymousSurvey && !isReceipt">
      <div v-if="isFrontPage" class="front-page-menu">
        <a class="home-icon" href="https://coreculture.dk">
          <img :src="homeIconUrl" alt="Tilbage til coreculture.dk">
        </a>
        <ul class="menu-items">
          <li class="hide-on-mobile">
            <a href="https://www.coreculture.dk/">FORSIDE</a>
          </li>
          <li class="active">
            <a href="#">MÅLING</a>
          </li>
          <li class="hide-on-mobile">
            <a href="https://www.coreculture.dk/app/">TOOLBOX</a>
          </li>
          <li class="hide-on-mobile">
            <a href="https://www.coreculture.dk/om-core-culture/">BAGGRUND</a>
          </li>
        </ul>
        <!-- <a href="https://coreculture.dk" style="font-size: 16px; line-height: 16px; color: #2a6889; padding: 3px;">Tilbage til coreculture.dk</a> -->
      </div>
      <base-ui-icon-burger-close
        :burgerState="burgerState"
        :darkTheme="darkTheme"
        @clicked="burgerClicked"
      ></base-ui-icon-burger-close>
    </nav>
    <base-message-bar
      v-if="showMessage"
      :message="'Du har pt. åbnet en gammel version af spørgeskemaet.'"
      @openLatestSurvey="openLatestSurvey"
    />
    <div v-if="loading" id="content-area" class="align-center">
      <h1>Loader...</h1>
    </div>
    <div v-if="error" id="content-area" class="align-center">
      <h3>{{errorMessage}}</h3>
    </div>
    <router-view v-if="!loading && !error" @update-background="updateBackgroundColor" @showArchieveMessage="showMessage = true"/>
  </div>
</template>

<script>
import BaseUiIconBurgerClose from './components/shared/BaseUiIconBurgerClose'
import { isDarkColor } from './sharedFunctions/helpers.js'
import homeIconUrl from './assets/imgs/Core-Culture-logo-new.svg'
import BaseMessageBar from './components/shared/BaseMessageBar.vue'

export default {
  components: { BaseUiIconBurgerClose, BaseMessageBar },
  data () {
    return {
      loading: true,
      error: false,
      errorMessage: '',
      bgColor: '#2a6889',
      darkTheme: true,
      homeIconUrl: homeIconUrl,
      showMessage: false
    }
  },
  computed: {
    loggedIn () {
      return this.$store.state.user.loggedIn
    },
    isFrontPage () {
      return (this.$route.path === '/' || this.$route.path === '/dashboard')
    },
    isAnonymousSurvey () {
      return (this.$route.name === 'externalResponse')
    },
    isReceipt () {
      return (this.$route.name === 'surveyReceipt')
    },
    burgerState () {
      return this.isFrontPage ? 'burger' : 'close'
    }
  },
  watch: {
    async loggedIn (newValue) {
      console.log('loggedIn changed')
      this.init()
    }
  },
  created () {
    console.log('app componnt created')
  },
  mounted () {
    this.init()
    setTimeout(() => {
      if (this.loading) {
        this.loading = false
        this.error = true
        this.errorMessage = 'Noget gik galt prøv at genindlæse siden og tjek evt. din internetforbindelse.'
      }
    }, 30000)
  },
  methods: {
    async init () {
      try {
        if (this.loggedIn !== null) {
          await this.$store.dispatch('questions/load')
          this.loading = false
        }
      } catch (error) {
        this.error = true
        // this.errorMessage = error.message
        this.errorMessage = 'Noget gik galt prøv at genindlæse siden.'
        this.loading = false
      }
    },
    updateBackgroundColor (color) {
      this.bgColor = color
      this.darkTheme = this.isFrontage ? true : isDarkColor(color)
    },
    burgerClicked (type) {
      type === 'close' ? this.closePage() : this.openMenu()
    },
    closePage () {
      console.log(this.$route)
      if (this.$route.query.from) {
        this.$router.push({ path: this.$route.query.from })
      } else {
        this.$router.push({ path: '/' })
      }
    },
    openMenu () {
      this.$router.push('/menu')
    },
    async openLatestSurvey () {
      const survey = await this.$store.dispatch('survey/getSurvey', { surveyId: null, loggedIn: true })
      console.log(survey)
      await this.$store.dispatch('survey/loadToStore', { id: survey.id, data: survey.data() })
      this.showMessage = false
      this.$router.push('/')
    }
  }
}
</script>

<style lang="stylus">
.front-page #content-area
  // margin-top 90px
  // padding 1rem 3rem

.front-page #nav
  height 100px
  background-color rgb(253, 252, 248)
  // box-shadow 0 1px 3px rgba(0, 0, 0, .11)
  justify-content space-between
  align-items center

.front-page-menu
  display flex
  align-items center

.home-icon
  width 48px
  height 48px
  margin-right 1rem

ul.menu-items
  list-style none
  display flex
  align-items center
  margin 0
  padding 0
  > li
    margin-right 1rem
    > a
      text-decoration none
      font-size 18px
      font-weight 400

ul.menu-items li.active
  border-bottom 1px solid rgb(237, 118, 94)
  > a
    color rgb(237, 118, 94)

</style>
