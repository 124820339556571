import { createRouter, createWebHashHistory } from 'vue-router'
import firebase from 'firebase/app'
import 'firebase/auth'

const routes = [
  {
    path: '/',
    alias: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue')
  },
  {
    path: '/menu',
    name: 'Menu',
    component: () => import(/* webpackChunkName: "about" */ '../views/Menu.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginAndSignup.vue')
  },
  {
    path: '/logud',
    name: 'logud',
    component: () => import(/* webpackChunkName: "login" */ '../views/LogOutReceipt.vue')
  },
  {
    path: '/vilkaar',
    name: 'TermsAndConditions',
    component: () => import(/* webpackChunkName: "login" */ '../views/TermsAndConditions.vue')
  },
  {
    path: '/nulstil-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "resetPassword" */ '../views/ResetPassword.vue')
  },
  {
    path: '/nyt-password',
    name: 'new-password',
    component: () => import(/* webpackChunkName: "resetPassword" */ '../views/CreateNewPassword.vue')
  },
  {
    path: '/slet-profil',
    name: 'delete-user',
    component: () => import(/* webpackChunkName: "resetPassword" */ '../views/DeleteUser.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/SignUp.vue')
  },
  {
    path: '/survey',
    name: 'survey',
    component: () => import(/* webpackChunkName: "survey" */ '../views/SurveyFlow.vue')
  },
  {
    path: '/udfyld/:id/sg/:scope',
    name: 'externalResponse',
    component: () => import(/* webpackChunkName: "survey2" */ '../views/SurveyFlow.vue'),
    meta: { anonymous: true },
    props: true
  },
  {
    path: '/del',
    name: 'share',
    component: () => import(/* webpackChunkName: "share" */ '../views/Share.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/arkiv',
    name: 'archieve',
    component: () => import(/* webpackChunkName: "archieve" */ '../views/Archieve.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/kvittering/:type',
    name: 'surveyReceipt',
    component: () => import(/* webpackChunkName: "sruveyReceipt" */ '../views/SurveyReceipt.vue'),
    props: true
  },
  {
    path: '/rapport/:type',
    name: 'report',
    component: () => import(/* webpackChunkName: "surveyReport" */ '../views/SurveyReport.vue'),
    props: true
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(route => route.meta.requiresAuth)
  const isAuthenticated = firebase.auth().currentUser
  document.getElementById('page').scrollTo(0, 0)
  if (requiresAuth && !isAuthenticated) {
    const query = to.fullPath ? to.fullPath : ''
    next(`/login?redirect=${query}&from=${from.fullPath}`)
  } else {
    next()
  }
})

export default router
