export const isDarkColor = (color) => {
  // Variables for red, green, blue values
  var r, g, b, hsp

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)

    r = color[1]
    g = color[2]
    b = color[3]
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

    r = color >> 16
    g = color >> 8 & 255
    b = color & 255
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  )
  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 180.5) {
    return false
  } else {
    return true
  }
}

export const copyToClipboard = (text) => {
  return window.navigator.clipboard.writeText((text || '')).then(
    () => { return { state: 'success' } },
    (msg) => { return { state: 'fail', error: msg } }
  )
}

export const isValueInObject = (obj, val) => {
  let result = false
  for (const key in obj) {
    if (obj[key] === val) {
      result = true
    }
  }
  return result
}

export const sanitize = (str) => {
  const sanitized = str.toLowerCase().replace(/[^a-z0-9]+/g, '-')
  return sanitized.replace(/(^-|-$)/g, '')
}

export const htmEl = (tagName, innerHtmlString, styleString) => {
  const el = document.createElement(tagName)
  if (innerHtmlString) {
    el.innerHTML = innerHtmlString
  }
  if (styleString) {
    el.style = styleString
  }
  return el
}

export const isEven = number => {
  return number % 2 === 0
}

export const isNotEmptyArray = arr => {
  return Array.isArray(arr) && arr[0]
}
