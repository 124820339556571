import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { isEven } from './helpers.js'

export const fb = {
  firebaseConfig: {
    apiKey: 'AIzaSyCLcK1zd9N9K1J7k4D9A-FcS4B1G3MFpQs',
    authDomain: 'coreculture-6a6dc.firebaseapp.com',
    projectId: 'coreculture-6a6dc',
    storageBucket: 'coreculture-6a6dc.appspot.com',
    messagingSenderId: '1002634797792',
    appId: '1:1002634797792:web:31b8f2170d5d6f124b6685'
  },
  init () {
    firebase.initializeApp(this.firebaseConfig)
  }
}
export const user = {
  onAuthStateChanged () {
    return firebase.auth().onAuthStateChanged
  },
  isLoggedIn () {
    return firebase.auth().currentUser
  },
  getEmail () {
    return firebase.auth().currentUser ? firebase.auth().currentUser.email : null
  },
  getUid () {
    return firebase.auth().currentUser.uid
  }
}
export const db = {
  getFirebaseRef (path) {
    // path is a string following the pattern
    // 'collectionName/documentId/collectionName/documentId'
    let ref = firebase.firestore()
    const pathElements = path.split('/')
    pathElements.forEach((element, i) => {
      ref = isEven(i) ? ref.collection(element) : ref.document(element)
    })
    return ref
  },
  async getDocumentsByQuery (path, queryArr) {
    try {
      const collectionRef = this.getFirebaseRef(path)
      const query = collectionRef.where(...queryArr)
      const result = await query.get()
      return result.docs.map(doc => doc.data())
    } catch (error) {
      throw new Error(error)
    }
  },
  async getDocumentById (path, id) {
    try {
      const collectionRef = this.getFirebaseRef(path)
      const docRef = collectionRef.doc(id)
      const snapshot = await docRef.get()
      return snapshot.data()
    } catch (error) {
      throw new Error(error)
    }
  },
  async createDocument (path, data) {
    try {
      const collectionRef = this.getFirebaseRef(path)
      return await collectionRef.add(data)
    } catch (error) {
      throw new Error(error)
    }
  },
  async setDocument (path, data) {
    try {
      const collectionRef = this.getFirebaseRef(path)
      return await collectionRef.set(data)
    } catch (error) {
      throw new Error(error)
    }
  }
}
