import { createStore } from 'vuex'
import survey from './survey'
import response from './response'
import questions from './questions'

export default createStore({
  state: {
    user: {
      loggedIn: null,
      data: null
    }
  },
  mutations: {
    SET_LOGGED_IN (state, value) {
      state.user.loggedIn = value
    },
    SET_USER (state, data) {
      state.user.data = data
    }
  },
  actions: {
    updateUser ({ commit }, user) {
      console.log('updating user')
      commit('SET_LOGGED_IN', user !== null)
      if (user) {
        commit('SET_USER', {
          displayName: user.displayName,
          email: user.email
        })
      } else {
        commit('SET_USER', null)
      }
    }
  },
  modules: {
    survey,
    response,
    questions
  }
})
