<template>
  <button
    @click="$emit('clicked')"
    :style="style"
    :class="{
      'sm': size === 'sm',
      'md': size === 'md'
    }"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    primary: {
      type: Boolean,
      default: false
    },
    bgColor: {
      type: String,
      default: '#a6a6a6'
    },
    color: {
      type: String,
      default: 'white'
    },
    size: {
      type: String,
      default: 'md'
    },
    outline: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    backgroundColor () {
      if (this.primary) {
        return '#ED765E'
      } else {
        return this.bgColor
      }
    },
    style () {
      let styleString = ''
      if (!this.outline) {
        styleString += `background-color: ${this.backgroundColor}; color: ${this.color};`
      } else {
        styleString += `background: none; border: 2px solid rgba(0,0,0,0.12); color: ${this.color}; box-shadow: none;`
      }
      return styleString
    }
  }
}
</script>

<style lang="stylus" scoped>
button
  min-width: 10rem;
  max-width: 100%;
  max-height: 2rem;
  border-radius 1.5rem
  border none
  box-shadow 0px 0px 6px rgba(0,0,0,.3)
  padding .5rem 1rem
  display flex
  justify-content center
  align-items center
  font-size 1rem
  line-height: 1rem;
  &:hover
    cursor: pointer;

button[disabled]
  opacity .4

button.sm
  width: auto
  min-width: 7rem;
  font-size .7rem
  padding .3rem 5rem
</style>
