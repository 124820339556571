import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

export default {
  namespaced: true,
  state: {
    id: '',
    status: 'unsaved',
    data: null
  },
  mutations: {
    setData (state, data) {
      state.data = data
    },
    setId (state, id) {
      state.id = id
    },
    setStatus (state, status) {
      state.status = status
    },
    setAnswerValue (state, payload) {
      state.data.answers[payload.id] = payload.value
    },
    setCompleted (state, value) {
      state.data.completed = value
    },
    setScope (state, scopeName) {
      if (!state.data.scope) {
        state.data.scope = ''
      }
      state.data.scope = scopeName
    }
  },
  actions: {
    async initResponseLoggedIn ({ commit, state, dispatch }, survey) {
      // this was used to save the demo response to db. But not used anymore
      console.log('user is logged in. Check for existing response in db', survey)
      const response = await dispatch('getUserResponse', survey.id)
      if (response) {
        console.log('found response in db.', response.data())
        const answersCompleted = function (answers) {
          let sum = 0
          for (const key in answers) {
            if (answers[key]) {
              sum++
            }
          }
          return sum
        }
        if (state.data && state.data.answers && (answersCompleted(state.data.answers) > answersCompleted(response.data().answers))) {
          console.log('replacing response in db with existing')
          firebase.firestore().collection('surveys').doc(survey.id).collection('responses').doc(response.id).set(state.data)
        } else {
          console.log('replacing response in store with response from db')
          await dispatch('load', { id: response.id, data: response.data() })
        }
      } else {
        console.log('no response in db is there one in the store?')
        if (state.data) {
          console.log('yep - save it')
          dispatch('save', { surveyId: survey.id, responseId: null, anonymous: false })
        } else {
          console.log('nope. dont do anything')
        }
      }
    },
    async create ({ commit, state, dispatch }, survey) {
      try {
        console.log('creating response', survey)
        const template = {
          answers: {},
          completed: false,
          scope: ''
        }
        survey.data.categories.forEach(cat => {
          cat.questions.forEach(q => {
            template.answers[q] = null
          })
        })
        commit('setData', template)
      } catch (e) {
        console.log(e)
      }
    },
    async load ({ commit, state, dispatch }, { id, data }) {
      try {
        console.log('loading response')
        commit('setId', id)
        commit('setData', data)
      } catch (e) {
        console.log(e)
      }
    },
    async save ({ commit, state, dispatch }, { surveyId, responseId, anonymous, scope }) {
      console.log('SCOPE: ', scope)
      try {
        const user = firebase.auth().currentUser
        if (surveyId && state.data) {
          const template = state.data
          template.ownedBy = user && !anonymous ? user.uid : ''
          template.scope = scope || ''
          const responsesRef = firebase.firestore().collection('surveys').doc(surveyId).collection('responses')
          if (!responseId) {
            // first save
            const newSurvey = await responsesRef.add(template)
            commit('setId', newSurvey.id)
          } else {
            await responsesRef.doc(responseId).set(template)
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    clear ({ commit }) {
      commit('setId', '')
      commit('setData', null)
    },
    async getUserResponse ({ commit, state, dispatch }, surveyId) {
      try {
        const user = firebase.auth().currentUser
        if (user && surveyId) {
          const responsesRef = firebase.firestore().collection('surveys').doc(surveyId).collection('responses')
          const querySnapShot = await responsesRef.where('ownedBy', '==', user.uid).get()
          const results = querySnapShot.docs
          if (results.length > 0) {
            return results[0]
          } else {
            return false
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
