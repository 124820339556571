<template>
  <div @click="toggle" @mouseenter="tooltip = true" @mouseleave="tooltip = false">
    <svg class="burger-btn" :class="burgerState === 'close' ? 'active' : ''" width="40" height="26" viewBox="0 0 40 26" xmlns="http://www.w3.org/2000/svg">
      <rect class="burger-btn--1" width="40" height="4" rx="3" ry="3" :fill="color"/>
      <rect class="burger-btn--2" width="40" height="4" y="10" rx="3" ry="3" :fill="color"/>
      <rect class="burger-btn--3" width="40" height="4" y="20" rx="3" ry="3" :fill="color"/>
    </svg>
    <span v-if="false && burgerState === 'close'">Tilbage til forsiden</span>
  </div>
</template>

<script>
export default {
  props: {
    darkTheme: {
      type: Boolean,
      default: true
    },
    burgerState: {
      type: String,
      default: 'burger'
    }
  },
  emits: ['clicked'],
  data () {
    return {
      tooltip: false
    }
  },
  computed: {
    color () {
      // return this.darkTheme ? 'rgb(253,252,248)' : '#2c3e50'
      return this.darkTheme ? 'rgb(253,252,248)' : '#2a6889'
    }
  },
  methods: {
    toggle () {
      this.$emit('clicked', this.burgerState)
    }
  }
}
</script>

<style lang="stylus" scoped>
.burger-btn
  overflow visible
  cursor pointer
  fill #1e272e
  padding-top 8px

.burger-btn--1
.burger-btn--2
.burger-btn--3
  transition .2s
  transform-origin center center

.burger-btn.active .burger-btn--1
  transform translate(-7px, 7px) rotate(45deg)

.burger-btn.active .burger-btn--2
  transform translateX(20px)
  opacity 0

.burger-btn.active .burger-btn--3
  transform translate(-7px, -7px) rotate(-45deg)

div
  position: relative;

span
  position: absolute;
  top: -18px;
  left: -10px;
  right: -10px;
  font-size: 9px;
  text-align: center;

</style>
