import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  namespaced: true,
  state: {
    data: null
  },
  mutations: {
    setData (state, data) {
      state.data = data
    },
    clear (state) {
      state.data = null
    }
  },
  actions: {
    async load ({ commit, state, dispatch }) {
      try {
        console.log('loading questions')
        const questionsRef = firebase.firestore().collection('questions')
        const snapshot = await questionsRef.get()
        const questions = []
        snapshot.docs.forEach(doc => {
          questions[doc.id] = doc.data()
        })
        commit('setData', questions)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
