import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { sanitize } from '../sharedFunctions/helpers'
import { db, user } from '../sharedFunctions/api'

export default {
  namespaced: true,
  state: {
    id: '',
    data: null
  },
  mutations: {
    setData (state, data) {
      state.data = data
    },
    setId (state, id) {
      state.id = id
    },
    addScope (state, scopeObj) {
      state.data.customScopes.push(scopeObj)
    },
    deleteScope (state, scopeId) {
      const scopes = state.data.customScopes
      const i = scopes.findIndex(scope => scope.id === scopeId)
      scopes.splice(i, 1)
    },
    deactivateScope (state, scopeId) {
      const scopes = state.data.customScopes
      const i = scopes.findIndex(scope => scope.id === scopeId)
      scopes[i].active = false
    },
    activateScope (state, scopeId) {
      const scopes = state.data.customScopes
      const i = scopes.findIndex(scope => scope.id === scopeId)
      scopes[i].active = true
    }
  },
  actions: {
    async initSurvey ({ commit, state, dispatch }, { surveyId, loggedIn }) {
      try {
        console.log('initializing survey. id: ' + surveyId + ' - loggedIn: ' + loggedIn)
        const survey = await dispatch('getSurvey', { surveyId, loggedIn })
        if (survey) {
          await dispatch('loadToStore', { id: survey.id, data: survey.data() })
        } else {
          await dispatch('create')
          console.log('create done')
          if (loggedIn) {
            await dispatch('save')
          }
        }
        return 'succes'
      } catch (error) {
        throw new Error(error)
      }
    },
    async create ({ commit, state, dispatch }) {
      try {
        if (!state.data) {
          console.log('creating survey in store')
          const templateRef = await firebase.firestore().collection('surveyTemplate').doc('t1nPPDlpRuA5YdqypT2D')
          const doc = await templateRef.get()
          console.log('got survey template from db', doc.data())
          const template = doc.data()
          commit('setData', template)
        } else {
          console.log('survey already exists in store')
        }
      } catch (e) {
        console.log(e)
      }
    },
    async loadToStore ({ commit, state, dispatch }, payload) {
      try {
        console.log('loading survey to store')
        commit('setId', payload.id)
        commit('setData', payload.data)
      } catch (e) {
        console.log(e)
      }
    },
    async save ({ commit, state, dispatch }) {
      try {
        const template = { ...state.data, ownedBy: user.getUid() }
        console.log('saving survey from store', template)
        if (state.id) {
          console.log('survey in store has id - saving to existing')
          await db.setDocument(`surveys/${state.id}`, template)
        } else {
          console.log('survey in store has no id - creating new', template)
          const newSurvey = await db.createDocument('surveys', template)
          console.log('New Survey: ', newSurvey)
          commit('setId', newSurvey.id)
        }
        console.log('survey saved')
      } catch (error) {
        throw new Error(error)
      }
    },
    clear ({ commit }) {
      commit('setId', '')
      commit('setData', null)
    },
    async getSurvey ({ commit, state, dispatch }, { surveyId, loggedIn }) {
      try {
        let survey
        if (surveyId) {
          survey = await dispatch('getSurveyById', surveyId)
        } else if (!surveyId && loggedIn) {
          survey = await dispatch('getUserSurvey')
        }
        return survey
      } catch (error) {
        throw new Error(error)
      }
    },
    async getSurveyById ({ commit, state, dispatch }, id) {
      console.log('Getting survey based on surveyId')
      const survey = await firebase.firestore().collection('surveys').doc(id).get()
      if (survey.exists) {
        return survey
      } else {
        throw new Error('Vi kunne ikke finde nogen survey med dette link.')
      }
    },
    async getUserSurvey ({ commit, state, dispatch }) {
      try {
        const user = firebase.auth().currentUser
        if (user) {
          console.log('Getting survey based on userId')
          const surveysRef = firebase.firestore().collection('surveys')
          const querySnapShot = await surveysRef.where('ownedBy', '==', user.uid).get()
          const results = querySnapShot.docs
          const surveys = []
          results.forEach(item => {
            surveys.push(item.data())
          })
          console.log('result: ', surveys)
          if (results.length > 0) {
            const templateRef = await firebase.firestore().collection('surveyTemplate').doc('t1nPPDlpRuA5YdqypT2D')
            const doc = await templateRef.get()
            const template = doc.data()
            let newestSurvey = false
            results.forEach(survey => {
              const data = survey.data()
              if (data.version === template.version) {
                newestSurvey = survey
              }
            })
            console.log(newestSurvey ? 'Found survey matching template version' : 'No survey matching template version')
            return newestSurvey
          } else {
            return false
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getAllUserSurveys ({ commit, state, dispatch }) {
      try {
        const user = firebase.auth().currentUser
        if (user) {
          console.log('Getting surveys based on userId')
          const surveysRef = firebase.firestore().collection('surveys')
          const querySnapShot = await surveysRef.where('ownedBy', '==', user.uid).get()
          const results = querySnapShot.docs
          return results
        }
      } catch (e) {
        console.log(e)
      }
    },
    async addScope ({ commit, state }, scopeName) {
      const sanitizedName = sanitize(scopeName)
      commit('addScope', {
        title: scopeName,
        id: sanitizedName,
        active: true
      })
      const surveyRef = firebase.firestore().collection('surveys').doc(state.id)
      await surveyRef.update({
        customScopes: state.data.customScopes
      })
    },
    async deleteScope ({ commit, state }, scopeId) {
      try {
        const surveyRef = firebase.firestore().collection('surveys').doc(state.id)
        await surveyRef.update({
          customScopes: state.data.customScopes.filter(scope => scope.id !== scopeId)
        })
        commit('deleteScope', scopeId)

        // delete all responses with this scope
        const responsesRef = firebase.firestore().collection('surveys').doc(state.id).collection('responses')
        const responses = await responsesRef.where('scope', '==', scopeId).get()
        responses.docs.forEach(doc => {
          responsesRef.doc(doc.id).delete()
        })
      } catch (error) {
        console.log(error)
      }
    },
    async getTemplate () {
      const templateRef = await firebase.firestore().collection('surveyTemplate').doc('t1nPPDlpRuA5YdqypT2D')
      const doc = await templateRef.get()
      const template = doc.data()
      return template
    }
  }
}
